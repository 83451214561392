import React from "react";
import { IoSync, IoLockClosed, IoDisc } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const EInvoicing = (props) => (
  <Layout>
    <Seo
      title="eInvoicing | Karmly Feature"
      description="eInvoicing is a new way to send and receive invoices directly via a dedicated secure global network. Now available with Karmly."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title={[
        <div
          key={0}
          className="badge -green -md"
          css={{ marginBottom: "10px" }}
        >
          NEW FEATURE
        </div>,
        <br key={1} />,
        <span key={2}>
          Get paid faster with
          <br className="hideOnMobile" key={3} /> eInvoicing software for
          recruitment agencies
        </span>,
      ]}
      description="eInvoicing is a new way to send and receive invoices directly via a dedicated secure global network. It means businesses can exchange invoices with no emails, PDFs or manual handling. Send accurate, secure invoices directly to your customers’ accounting software."
      image="InvoxyFeature_eInvoicing2.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoSync />
              <br />
              Improved Cashflow
            </h3>
            <p>
              NZ Government agencies have adopted a target of paying 95% of all
              eInvoices within 10 business days.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoLockClosed />
              <br />
              Direct & Secure
            </h3>
            <p>
              eInvoices from Karmly are exchanged via the secure global Peppol
              network, protected by strict security protocols and encryption.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoDisc />
              <br />
              Greater Accuracy
            </h3>
            <p>
              The direct exchange of invoices between accounting systems means
              invoice processing isn’t delayed due to data entry errors or lost
              invoices.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_eInvoicing_Secure.png"
              alt="Faster Payments | Karmly eInvoicing"
              maxWidth={350}
              centerImage
            />
          </Box>
          <Box size={50}>
            <h3>Faster Payments</h3>
            <p>
              No manual handling of your invoices means no more errors. Invoices
              will get to the right place without getting lost or caught in spam
              filters, reducing delays in processing and payment of your
              invoices by your clients. This includes NZ Government agencies,
              who have committed to paying 95% of eInvoices within 10 days of
              receipt.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Better For Your Clients</h3>
            <p>
              Save your customers time and money. On average it costs a business
              $7.63 to process an eInvoice, compared to $23.01 for a pdf
              invoice. eInvoicing takes the admin out of paying bills, and
              reduces data entry for recipients.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_eInvoicing_Clients.png"
              alt="Better For Your Clients | Karmly eInvoicing"
              maxWidth={450}
              centerImage
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_eInvoicing_Faster.png"
              alt="Direct and Secure | Karmly eInvoicing"
              maxWidth={450}
              centerImage
            />
          </Box>
          <Box size={50}>
            <h3>Direct and Secure</h3>
            <p>
              eInvoices minimise the risk of fake or compromised invoices,
              reduce the chance of paper or PDF invoices being intercepted, and
              they don’t get lost. Sender and receiver details are validated,
              audit logs kept, and strict protocols are followed to ensure
              invoices remain secure in the Peppol network.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Improved Visibility</h3>
            <p>
              Track the status of your eInvoices and when they've been received
              or if anything has gone wrong. Better visibility supports
              successful forecasting, planning and budgeting, and more accurate
              financial management.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_eInvoicing_Visible.png"
              alt="Improved Visibility | Karmly eInvoicing"
              maxWidth={500}
              centerImage
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_eInvoicing.png"
              alt="Direct and Secure | Karmly eInvoicing"
              centerImage
            />
          </Box>
          <Box size={50}>
            <h3>Stay Ahead of the Curve</h3>
            <p>
              All New Zealand Central Government departments aim to migrate 90%
              of B2G invoices to eInvoices by July 2026. Australia has already
              mandated eInvoices for all Commonwealth agencies since July 2022
              and is now investigating options for the B2B sector.
            </p>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default EInvoicing;
